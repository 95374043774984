import React from "react";
import { TextLight, TextMedium, TextRegular } from "../base/BaseText";
import BaseIcon from "../base/BaseIcon/BaseIcon";
import CloseIcon from "../images/icon-close.svg";
import CarrierLogo from "../images/asurion-pay.png";
import BaseLabel from "../base/BaseLabel";
import { Translate } from "../helpers/languageUtils";
import BaseCard from "../base/BaseCard/BaseCard";

const ServiceUnavailable = () => {
  return (
    <div className="w-full h-full bg-app-bg flex flex-col overflow-auto">
      <BaseIcon className="my-16" icon={CarrierLogo} name="Asurion Payment Portal" center />

      <BaseCard className="bg-white lg:max-w-2xl py-10">
        <BaseLabel className="mx-auto text-3xl text-center">
          <TextRegular>
            <Translate i18nKey="ServiceUnavailable.PrimaryHeader">
              503 Service Unavailable
            </Translate>
          </TextRegular>
        </BaseLabel>
        <BaseLabel className="mx-auto text-3xl text-center">
          <TextRegular>
            <Translate i18nKey="ServiceUnavailable.Content">
              Sorry for inconvience!
            </Translate>
          </TextRegular>
        </BaseLabel>
        <BaseLabel className="mx-4 text-3xl text-center">
          <TextRegular>
            <Translate i18nKey="ServiceUnavailable.Content1">
              We were unable to process your request due to a technical issue.
            </Translate>
          </TextRegular>
        </BaseLabel>
        <BaseLabel className="mx-auto text-3xl font-DINPro-Regular text-center">
          <TextRegular>
            <Translate i18nKey="ServiceUnavailable.Content2">
              Please try after sometime
            </Translate>
          </TextRegular>
        </BaseLabel>
      </BaseCard>
    </div>
  );
};

export default ServiceUnavailable;
