import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const BaseCard = ({ children, className }) => {
  const extraClasses = className ? className.split(" ") : [];
  return (
    <div className={classNames("app-BaseCard", ...extraClasses)}>
      {children}
    </div>
  );
};

BaseCard.propTypes = {
  className: PropTypes.string,
};

export default BaseCard;
