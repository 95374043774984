const aDay = 86400000;

const Months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const MonthsSmall = [
  "Jan",
  "Feb",
  "March",
  "April",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

const Days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const covertToUTCDate = (date = new Date()) => {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
    date.getUTCMilliseconds()
  ).toString();
};

export const getDateFromUtcTimeZone = (offset, date = new Date()) => {
  const offsetMultiplier = 3600000;
  const utcMs = new Date(covertToUTCDate(date)).getTime();
  const returnDate = new Date(utcMs + offsetMultiplier * offset);
  return returnDate;
};

export const getReadableDate = (dateStr) => {
  return new Date(dateStr).toDateString();
};

export const getFormmatedDate = (strDate, format = "d M yyyy hh:mm a", split = " ") => {
  const formats = format.split(split);
  const dateArray = [];
  // const dateObj = getDateFromUtcTimeZone(0, new Date(strDate));
  const dateObj = new Date(strDate);
  const day = dateObj.getDay();
  const dayText = Days[day];
  const date = dateObj.getDate();
  const month = dateObj.getMonth();
  const monthText = MonthsSmall[month];
  const year = dateObj.getFullYear();
  const hours = dateObj.getHours();
  const minutes = dateObj.getMinutes();

  formats.map((f) => {
    switch (f) {
      case "d":
        dateArray.push(date);
        return date;
      case "dd":
        const dd = ("0" + date).slice(-2);
        dateArray.push(dd);
        return dd;
      case "D":
        dateArray.push(dayText);
        return dayText;
      case "DDD":
        const dtext = dayText.substr(0, 3);
        dateArray.push(dtext);
        return dtext;
      case "m":
        dateArray.push(month + 1);
        return month + 1;
      case "mm":
        const mm = ("0" + (month + 1)).slice(-2);
        dateArray.push(mm);
        return mm;
      case "M":
        dateArray.push(monthText);
        return monthText;
      case "MMM":
        const mtext = monthText.substr(0, 3);
        dateArray.push(mtext);
        return mtext;
      case "yyyy":
        dateArray.push(year);
        return year;
      case "yy":
        const ytext = year.substr(2, 2);
        dateArray.push(ytext);
        return ytext;
      case "hh:mm":
        const time = `${("0" + hours).slice(-2)}:${("0" + minutes).slice(-2)}`
        dateArray.push(time);
        return `${time}`;
      case "a":
        const a = hours >= 12 ? "pm" : "am" 
        dateArray.push(a);
        return a;
      default:
        return "";
    }
  });
  return dateArray.join(split);
};

export const isToday = (someDate) => {
  const today = new Date();
  return someDate === today.toLocaleDateString();
};

export const isTimeBetweenBusinessHour = (sTime = 9, eTime = 21) => {
  const today = getDateFromUtcTimeZone(8);
  const hour = today.getHours();

  return hour >= sTime && hour < eTime;
};

export const isValidExpiryDate = (month, year) => {
  const givenMonth = parseInt(month);
  const givenYear = parseInt(year);
  const today = getDateFromUtcTimeZone(8);
  const currentMonth = parseInt(today.getMonth()) + 1;
  const currentYear = parseInt(today.getFullYear());
  return (
    givenMonth > 0 &&
    givenMonth < 13 &&
    (givenYear > currentYear ||
      (givenMonth > currentMonth && givenYear === currentYear))
  );
};
