const EVENTLIST = {
  ASURION_PAYMENT_PORTAL: "Asurion Payment Portal",
  HOME_PAGE: "Home Page - Asurion Payment Portal",
  PAYMENT_DETAILS_PAGE: "Payment Details Page - Asurion Payment Portal",
  PAYMENT_VERIFIED_PAGE: "Payment Verified Page - Asurion Payment Portal",
  GET_QR_API: "Get QR Api - Asurion Payment Portal",
  PAYMENT_STATUS_SUBSCRIPTION: "Payment Status Subscription - Asurion Payment Portal",
  PAYMENT_STATUS_API: "Payment Status Api - Asurion Payment Portal",
};

export default EVENTLIST;
