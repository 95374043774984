import gql from "graphql-tag";

export const SubscribeToPaymentStatusUpdate = gql`
  subscription onPaymentStatusUpdate($billReferenceNumber: String!) {
    onPaymentStatusUpdate(billReferenceNumber: $billReferenceNumber) {
      caseNumber
      mobileNumber
      paymentStatus
      errorMessage
      createdAt
      billReferenceNumber
      totalAmount
      ICN_timeStamp
    }
  }
`;

export const CreatePaymentStatusMutation = gql`
  mutation createPaymentStatus($input: PaymentStatusInput!) {
    createPaymentStatus(input: $input) {
      caseNumber
      mobileNumber
      paymentStatus
      errorMessage
      createdAt
      billReferenceNumber
      totalAmount
    }
  }
`;

export const UpdatePaymentStatusMutation = gql`
  mutation updatePaymentStatus($input: UpdatePaymentStatusInput!) {
    updatePaymentStatus(input: $input) {
      caseNumber
      mobileNumber
      paymentStatus
      errorMessage
      createdAt
      billReferenceNumber
      totalAmount
    }
  }
`;

export const GetPaymentStatusQuery = gql`
  query getPaymentStatus($billReferenceNumber: String!) {
    getPaymentStatus(billReferenceNumber: $billReferenceNumber) {
      caseNumber
      mobileNumber
      paymentStatus
      errorMessage
      createdAt
      billReferenceNumber
      totalAmount
    }
  }
`;
