import DOMPurify from "dompurify";

export const toPascalCase = (text) =>
  text.replace(/\w+/g, function (w) {
    return w[0].toUpperCase() + w.slice(1).toLowerCase();
  });

export const sanitize = (value) => {
  return DOMPurify.sanitize(value);
};

export const isEmpty = (input) => {
  if (typeof input === "undefined" || input === "null") {
    return true;
  }
  if (typeof input === "function") {
    return false;
  }
  if (Array.isArray(input)) {
    return input.length === 0;
  }
  return !input || Object.keys(input).length === 0;
};

export function getMaskedCCNum(str) {
  if (str !== "") {
    str = new Array(str.length - 3).join("*") + str.substr(str.length - 4, 4);
  }
  return str;
}

export function getFormattedUserName(name = "") {
  console.log("getFormattedUserName", name);
  name = name ? name.replace(/\s{2,}/g, " ") : name;
  let isSpaceFound = name.indexOf(" ") > -1;
  name = isSpaceFound ? name.split(" ") : name;

  if (isSpaceFound) {
    var [firstName, ...rest] = name;
  }

  return {
    firstName: isSpaceFound ? firstName : name,
    lastName: isSpaceFound && rest ? rest.join(" ") : "",
    fullName: name.length > 0 && rest ? `${firstName} ${rest.join(" ")}` : name,
  };
}

export const getMaskedMdn = (mdn) => {
  let maskedMdn = mdn;
  if (mdn.length === 8) {
    maskedMdn = mdn[0] + "**" + mdn.substr(3, 2) + "**" + mdn[7];
  } else if (mdn.length === 9) {
    maskedMdn = mdn[0] + "**" + mdn.substr(4, 2) + "**" + mdn.substr(7, 2);
  } else if (mdn.length === 10) {
    maskedMdn = mdn[0] + "***" + mdn.substr(4, 2) + "**" + mdn.substr(8, 2);
  } else if (mdn.length === 11) {
    maskedMdn = mdn[0] + "***" + mdn.substr(5, 2) + "***" + mdn.substr(9, 2);
  }
  return maskedMdn;
};

export const getMaskedEmail = (emailAddress) => {
  let maskid = "";
  let myemailId = emailAddress;
  let prefix = myemailId.substring(0, myemailId.lastIndexOf("@"));
  let postfix = myemailId.substring(myemailId.lastIndexOf("@"));

  for (let i = 0; i < prefix.length; i++) {
    if (i == 0 || i % 2 == 1) {
      maskid = maskid + prefix[i].toString();
    } else {
      maskid = maskid + "*";
    }
  }
  myemailId = maskid + postfix;
  return myemailId;
};

export const getMaskedNric = (code) => {
  let maskedCode = code;
  if (code.length === 4) {
    maskedCode = code[0] + "**" + code[3];
  } else if (code.length >= 11) {
    maskedCode =
      code.substr(0, 2) +
      "*" +
      code.substr(3, 2) +
      "*" +
      code.substr(6, 3) +
      new Array(code.length - 9).join("*") +
      code[code.length - 1];
  }
  return maskedCode;
};
