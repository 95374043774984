import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  qrCode: {},
  inputData: {},
  paymentStatus: {},
  showServiceUnavailable: false
};

const validationReducer = createSlice({
  name: "validation",
  initialState,
  reducers: {
    resetStore() {
      return initialState;
    },
    callGenerateQrCodeApi(state, action) {
      state.qrCode = action.payload || {};
    },
    callGenerateQrCodeApiSuccess(state, action) {
      state.qrCode = action.payload.GenerateQRResponse || {};
    },
    callGenerateQrCodeApiFailure(state, action) {
      state.error = action.payload;
    },
    setUserDetails(state, action) {
      state.inputData.mdn = action.payload.mdn;
      state.inputData.caseNumber = action.payload.caseNumber;
    },
    setShowServiceUnavailable(state, action) {
      state.showServiceUnavailable = action.payload;
    },
    callCheckPaymentStatusApi(state, action) {
      state.paymentStatus = action.payload || {};
    },
    callCheckPaymentStatusApiSuccess(state, action) {
      state.paymentStatus = action.payload.VerifyPaymentStatusResponse || {};
    },
    callCheckPaymentStatusApiFailure(state, action) {
      state.error = action.payload;
    },
    setPaymentStatus(state, action) {
      state.paymentStatus = action.payload || {};
    },
  },
});

const { actions, reducer } = validationReducer;
export const {
  resetStore,
  callGenerateQrCodeApi,
  callGenerateQrCodeApiFailure,
  setUserDetails,
  setShowServiceUnavailable,
  callCheckPaymentStatusApi,
  callCheckPaymentStatusApiFailure,
  setPaymentStatus
} = actions;
export default reducer;
