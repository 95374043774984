import React from "react";

import { Provider } from "react-redux";
import store from "./store";

import Home from "./modules/Validation/Home";

import TagManager from "react-gtm-module";
import Config from "./modules/Appsync/Config";

const tagManagerArgs = {
  gtmId: Config.GTM.gtmId,
};

function App() {

  TagManager.initialize(tagManagerArgs);

  return (
    <Provider store={store}>
      <Home />
    </Provider>
  );
}

export default App;
