import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslate, Translate } from "../../helpers/languageUtils";
import BaseButton from "../../base/BaseButton/BaseButton";
import BaseCard from "../../base/BaseCard/BaseCard";
import BaseLabel from "../../base/BaseLabel";
import CloseIcon from "../../images/ic-close.png";
import asurionPay from "../../images/asurion-pay.png";
import online from "../../images/online-icon.png";
import offline from "../../images/offline-icon.png";
import onlineMobile from "../../images/online-icon-mobile.png";
import offlineMobile from "../../images/offline-icon-mobile.png";
import BaseTable, {
  BaseTR,
  BaseTD,
  BaseTH,
} from "../../base/BaseTable/BaseTable";
import { TextMedium, TextRegular } from "../../base/BaseText";
import { isEmpty } from "../../helpers/formatUtils";
import { checkPaymentStatus } from "./state/operators";
import SendMixpanelEvent from "../../mixpanel/sendMixpanelEvent";
import EVENTLIST from "../../mixpanel/EVENTLIST";

const PaymentDetails = (props) => {
  const t = useTranslate();
  const dispatch = useDispatch();
  const [freezeWindow, setFreezeWindow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [disable, setDisable] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  const validation = useSelector((state) => state.validation);

  setTimeout(() => {
    setDisable(false);
  }, 10000);

  const {
    qrCode,
    inputData: { mdn, caseNumber },
  } = validation;

  const tableHeaders = [
    {
      key: "CaseNumber",
      text: t("PaymentDetails.CaseNumber"),
    },
    {
      key: "MobileNumber",
      text: t("PaymentDetails.MobileNumber"),
    },
  ];

  const tableData = [
    {
      CaseNumber: qrCode.CustomerCaseNo,
      MobileNumber: qrCode.MobileDeviceNumber,
    },
  ];

  useEffect(() => {
    dispatch(
      SendMixpanelEvent(EVENTLIST.PAYMENT_DETAILS_PAGE, {
        MDN: qrCode.MobileDeviceNumber,
        CaseNumber: qrCode.CustomerCaseNo,
        SRFee: qrCode.TotalAmount,
      })
    );
  }, []);

  const getPaymentStatus = async () => {
    setDisable(true);
    setFreezeWindow(true);
    setLoading(true);
    let response = await dispatch(checkPaymentStatus(mdn, caseNumber));
    setLoading(false);

    dispatch(
      SendMixpanelEvent(EVENTLIST.PAYMENT_STATUS_API, {
        Data: response,
      })
    );

    if (!isEmpty(response.VerifyPaymentStatusResponse)) {
      if (
        response.VerifyPaymentStatusResponse.paymentStatus.toUpperCase() ===
        "SUCCESS"
      ) {
        props.showPaymentDetailsPage();
      } else if (
        response.VerifyPaymentStatusResponse.paymentStatus.toUpperCase() ===
          "PENDING" ||
        response.VerifyPaymentStatusResponse.paymentStatus.toUpperCase() ===
          "FAILED"
      ) {
        setErrorMessage();
        setFreezeWindow(false);
        setTimeout(() => {
          setDisable(false);
        }, 10000);
      }
    }
  };

  return (
    <div className="h-full text-center bg-app-bg">
      <div className="hidden lg:block">
        <div className="pt-24 relative">
          <img className="inline-block" src={asurionPay} alt="logo" />
          {/* <img
            className="absolute inline-block w-32 right-30"
            src={
              isEmpty(props.status) || props.status === "closed"
                ? offline
                : online
            }
            alt="status"
          /> */}
        </div>
      </div>

      <div className="block lg:hidden w-full px-5 bg-white">
        <div className="w-85% inline-block text-left">
          <BaseLabel className="p-3 text-black text-3xl inline-block">
            <TextMedium>
              <Translate i18nKey="PaymentDetails.Header">Scan to Pay</Translate>
            </TextMedium>
          </BaseLabel>
          {/* <img
            className="inline-block w-12 p-0 pl-2"
            src={
              isEmpty(props.status) || props.status === "closed"
                ? offlineMobile
                : onlineMobile
            }
            alt="status"
          /> */}
        </div>
        <div className="inline-block text-center">
          <img
            className="p-0"
            src={CloseIcon}
            onClick={props.RedirectToHomePage}
            alt="close"
          />
        </div>
      </div>
      <BaseCard className="bg-white lg:max-w-4xl py-10">
        <div className="block lg:flex lg:justify-between">
          <div>
            <img
              className="p-0 m-auto max-w-xs"
              src={`data:image/jpeg;base64,${qrCode.ImageStreamInBase64Format}`}
              alt="scan image"
            />

            <BaseLabel className="lg:hidden px-10 text-text-light text-2xl">
              <TextRegular>
                <Translate i18nKey="PaymentDetails.ScanText">
                  Please show this QR code to the customer to make payment
                </Translate>
              </TextRegular>
            </BaseLabel>
          </div>
          <div className="block lg:hidden border-b-2 my-10 border-dashed border-card-border" />
          <div className="pl-3">
            <div className="bg-app-payment p-3">
              <BaseLabel className="text-black text-3xl my-0">
                <TextRegular>
                  <Translate i18nKey="PaymentDetails.ServiceFee">
                    Service Fee
                  </Translate>
                </TextRegular>
              </BaseLabel>
              <BaseLabel className="text-text-highlight text-4xl my-0">
                <TextMedium>{`SGD $${qrCode.TotalAmount}`}</TextMedium>
              </BaseLabel>
            </div>

            <BaseTable className="px-3" verticle>
              {tableHeaders.map((header) => {
                return (
                  <BaseTR key={header.key}>
                    <BaseTH>{header.text}</BaseTH>
                    {tableData.map((data, i) => (
                      <BaseTD key={`${header.key}_${i}`}>
                        {data[header.key]}
                      </BaseTD>
                    ))}
                  </BaseTR>
                );
              })}
            </BaseTable>

            <BaseLabel className="hidden lg:block text-text-light text-2xl">
              <TextRegular>
                <Translate i18nKey="PaymentDetails.ScanText">
                  Please show this QR code to the customer to make payment
                </Translate>
              </TextRegular>
            </BaseLabel>
          </div>
        </div>
        <div className="hidden lg:block border-b-2 my-10 border-dashed border-card-border" />

        <BaseButton
          className="w-28 m-auto mt-10 font-Apercu-Light"
          name="Submit"
          text={t(`PaymentDetails.CheckStatus`)}
          onClick={getPaymentStatus}
          clicked={freezeWindow}
          disabled={disable}
          focused
        />
      </BaseCard>
    </div>
  );
};

export default PaymentDetails;
