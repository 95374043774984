import React from "react";
import { useTranslation, Trans } from "react-i18next";
import { useSelector } from "react-redux";
import i18n from "../i18n";
import { CLIENT, LANGUAGE } from "./constants";

export const useTranslate = () => {
  const { t } = useTranslation([`translation`]);
  return t;
};

export const Translate = ({ children, i18nKey, ...rest }) => {
  return (
    <Trans ns={`translation`} i18nKey={i18nKey} {...rest}>
      {children}
    </Trans>
  );
};

export const changeLanguage = (language) => {
  i18n.changeLanguage(language);
};

export const setInitialLanguage = (clientName) => {
  const lang =
    clientName === CLIENT.SINGTEL ? LANGUAGE.ENGLISH : LANGUAGE.BAHASA;
  return lang;
};
