import AppSyncApis from "./AppSyncApis";

let Subscriptions = {};

const useAppSync = ({
  handleError,
  handlePaymentStatusUpdate,
  handleStatusUpdate
}) => {

  const subscribeToPaymentStatus = async (billReferenceNumber) => {
    const subscription = await AppSyncApis.SubscribeToPaymentStatusUpdate(
      billReferenceNumber
    );

    Subscriptions = {
      ...Subscriptions,
      PaymentStatusUpdate: subscription.subscribe({
        next: (data) =>
          handleSubscriptions(
            data.value.data.onPaymentStatusUpdate,
          ),
        error: handleError ? handleError("PAYMENT_STATUS") : () => {},
      }),
    }

    // calling to show subscription status
    getState();

  };

  const handleSubscriptions = async (paymentStatus) => {
    handlePaymentStatusUpdate(paymentStatus)
  }

  const clearAll = () => {

    Object.keys(Subscriptions).forEach((s) => {
      console.log("s::", s);
      Subscriptions[s] && Subscriptions[s].unsubscribe && Subscriptions[s].unsubscribe();
    });

    Subscriptions = {};

  };

  const getState = () => {

    let state = "";

    Object.keys(Subscriptions).forEach((s) => {
      state = Subscriptions[s]._state
    });

    handleStatusUpdate(state);

  };

  //clear all subscription and timers being used
  const unSubscribe = async () => {  

    //reset all state and clear subscription
    clearAll();

  };

  //clear all subscription and timers being used
  const isSubscriptionActive = async () => {  

    getState();
  };

  return { subscribeToPaymentStatus, unSubscribe, isSubscriptionActive };

}

export default useAppSync;
