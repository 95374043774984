import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { TextMedium, TextLight, TextRegular } from "../BaseText";
import { isEmpty } from "../../helpers/formatUtils";

const BaseTable = ({
  id,
  title,
  className,
  children,
  verticle,
  ...restProps
}) => {
  const extraClasses = className ? className.split(" ") : [];

  return (
    <div className={classNames("app-BaseTable", ...extraClasses)}>
      <div
        className={
          isEmpty(title)
            ? "app-BaseTable__title hidden"
            : "app-BaseTable__title pt-0"
        }
      >
        {title}
      </div>
      <table
        className={classNames("app-BaseTable__table", {
          "app-BaseTable__table-verticle1": verticle,
        })}
        {...restProps}
      >
        <tbody>{children}</tbody>
      </table>
    </div>
  );
};

BaseTable.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
};

export default BaseTable;

export const BaseTR = ({ children, className, ...restProps }) => {
  const extraClasses = className ? className.split(" ") : [];
  return (
    <tr
      className={classNames("app-BaseTable__tr", ...extraClasses)}
      {...restProps}
    >
      {children}
    </tr>
  );
};

export const BaseTH = ({ children, className, ...restProps }) => {
  const extraClasses = className ? className.split(" ") : [];
  return (
    <th
      className={classNames("app-BaseTable__th font-normal", ...extraClasses)}
      {...restProps}
    >
      <TextRegular>{children}</TextRegular>
    </th>
  );
};

export const BaseTD = ({ children, className, ...restProps }) => {
  const extraClasses = className ? className.split(" ") : [];
  return (
    <td
      className={classNames("app-BaseTable__td", ...extraClasses)}
      {...restProps}
    >
      <TextMedium>{children}</TextMedium>
    </td>
  );
};
