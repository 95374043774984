import isomorphicFetch from "isomorphic-fetch";

export default (url, method, body, noHeaders = false) => {
  let options = {
    method,
    body: method !== "GET" ? JSON.stringify(body) : null,
  };

  if (!noHeaders) {
    options.headers = requestHeaders();
  }

  return isomorphicFetch(url, options).then((res) =>
    parseStatus(res.status, res.json())
  );
};

function parseStatus(status, res) {
  return new Promise((resolve, reject) => {
    if (status >= 200 && status < 300) {
      res.then((response) => resolve(response));
    } else {
      res.then((response) => reject({ status, response }));
    }
  });
}

function requestHeaders() {
  return {
    Accept: "application/json",
    "Content-Type": "application/json",
    "x-api-key": process.env.REACT_APP_SLS_API_KEY,
    "asurion-channel": "WEB"
  };
}
