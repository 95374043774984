
import { API, graphqlOperation, Auth } from 'aws-amplify';
import fetch from "../../../helpers/fetch";
import {
    SubscribeToPaymentStatusUpdate,
  } from '../Queries';

const AppSyncApis = {
    getCognitoId: () => Auth.currentCredentials(),
    getIpAddress: () => {
      return fetch('https://jsonip.com/', { method: 'GET' }).then((response) => response.json())
    },

    SubscribeToPaymentStatusUpdate: (billReferenceNumber) => {
      return API.graphql(graphqlOperation(SubscribeToPaymentStatusUpdate, { billReferenceNumber }))
    },
}

export default AppSyncApis;