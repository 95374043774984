
import mixpanel from "mixpanel-browser";

//let mp_active = process.env.REACT_APP_IS_LOCAL_ENVIORNMENT != 'TRUE' ; //!process.env.REACT_APP_IS_LOCAL_ENVIORNMENT
//let mp = window.mixpanel;
//mixpanel.init('YOUR_MIXPANEL_TOKEN');
const mixpanelActions = function (mp) {
    return {
        init: (token) => {
            console.log("initiated")
            mp.init(token);
        },
        time_event: (name) => {
            mp.time_event(name);
        },
        track: (name, props, callback) => {
            mp.track(name, props, callback);
        },
        register: (value) => {
            mp.register(value);
        },
        unregister: (value) => {
            mp.unregister(value);
        },
        register_once: (value) => {
            mp.register_once(value);
        },
        identify: (value) => {
            mp.identify(value);
        },
    }
}

const MIXPANEL = mixpanelActions(mixpanel);

export default MIXPANEL
