import React from "react";

export const TextRegular = ({ children }) => {
  return <span className="font-Apercu-Regular">{children}</span>;
};

export const TextLight = ({ children }) => {
  return <span className="font-Apercu-Light">{children}</span>;
};

export const TextMedium = ({ children }) => {
  return <span className="font-Apercu-Medium">{children}</span>;
};

export const TextBold = ({ children }) => {
  return <span className="font-Apercu-Bold">{children}</span>;
};