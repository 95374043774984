import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslate, Translate } from "../../helpers/languageUtils";
import BaseButton from "../../base/BaseButton/BaseButton";
import BaseCard from "../../base/BaseCard/BaseCard";
import BaseLabel from "../../base/BaseLabel";
import paymentReceived from "../../images/payment-received.png";
import asurionPay from "../../images/asurion-pay.png";
import BaseTable, {
  BaseTR,
  BaseTD,
  BaseTH,
} from "../../base/BaseTable/BaseTable";
import { TextMedium, TextRegular } from "../../base/BaseText";
import { getFormmatedDate } from "../../helpers/dateUtils";
import { isEmpty } from "../../helpers/formatUtils";
import SendMixpanelEvent from "../../mixpanel/sendMixpanelEvent";
import EVENTLIST from "../../mixpanel/EVENTLIST";

const PaymentReceiveDetails = (props) => {
  const t = useTranslate();
  const dispatch = useDispatch();
  const [freezeWindow, setFreezeWindow] = useState(false);

  const validation = useSelector((state) => state.validation);

  const { paymentStatus } = validation;

  useEffect(() => {
    dispatch(SendMixpanelEvent(EVENTLIST.PAYMENT_VERIFIED_PAGE))
  }, []);

  const tableHeaders = [
    {
      key: "CaseNumber",
      text: t("PaymentReceivedDetails.CaseNumber"),
    },
    {
      key: "MobileNumber",
      text: t("PaymentReceivedDetails.MobileNumber"),
    },
    {
      key: "DateAndTime",
      text: t("PaymentReceivedDetails.DateAndTime"),
    },
    {
      key: "BillReferenceNumber",
      text: t("PaymentReceivedDetails.BillReferenceNumber"),
    },
  ];

  const tableData = [
    {
      CaseNumber: paymentStatus.caseNumber,
      MobileNumber: paymentStatus.mobileNumber,
      DateAndTime: !isEmpty(paymentStatus.ICN_timeStamp) ? getFormmatedDate(paymentStatus.ICN_timeStamp) : "",
      BillReferenceNumber: paymentStatus.billReferenceNumber,
    },
  ];

  return (
    <div className="h-full text-center bg-app-bg">
      <img
        className="hidden lg:block m-auto pt-24"
        src={asurionPay}
        alt="logo"
      />

      <div className="block lg:hidden w-full px-5 bg-white">
        <BaseLabel className="w-full p-3 text-black text-3xl inline-block text-left">
          <TextMedium>
            <Translate i18nKey="PaymentReceivedDetails.Header">
              Payment Details
            </Translate>
          </TextMedium>
        </BaseLabel>
      </div>
      <BaseCard className="block bg-white lg:max-w-1.5xl py-10 lg:px-20">
        <div className="m-auto">
          <img
            className="m-auto"
            src={paymentReceived}
            alt="payment received image"
          />

          <BaseLabel className="px-10 text-black text-3xl font-bold">
            <TextMedium>
              <Translate i18nKey="PaymentReceivedDetails.PaymentSuccessful"></Translate>
            </TextMedium>
          </BaseLabel>
        </div>
        <div className="bg-app-payment p-3 mt-4">
          <BaseLabel className="text-black text-3xl font-medium my-0">
            <TextRegular>
              <Translate i18nKey="PaymentReceivedDetails.PaymentReceived">
                PaymentReceived
              </Translate>
            </TextRegular>
          </BaseLabel>
          <BaseLabel className="text-text-highlight text-4xl my-0">
            <TextMedium>{`SGD $${paymentStatus.totalAmount}`}</TextMedium>
          </BaseLabel>
        </div>

        <div className="border-b-2 my-10 border-dashed border-card-border" />

        <BaseTable
          className="m-auto"
          title={t(`PaymentReceivedDetails.TableTitle`)}
          verticle
        >
          {tableHeaders.map((header) => {
            return (
              <BaseTR key={header.key}>
                <BaseTH>{header.text}</BaseTH>
                {tableData.map((data, i) => (
                  <BaseTD key={`${header.key}_${i}`}>{data[header.key]}</BaseTD>
                ))}
              </BaseTR>
            );
          })}
        </BaseTable>

        <BaseButton
          className="w-28 lg:w-full m-auto mt-5"
          name="Submit"
          text={t(`PaymentReceivedDetails.Close`)}
          onClick={props.RedirectToHomePage}
          clicked={freezeWindow}
          focused
        />
      </BaseCard>
    </div>
  );
};

export default PaymentReceiveDetails;
