//async action creator or thunk

import { generateQRCodeApi, serviceUnavailable, checkPaymentStatusApi } from "./actions";

export const generateQRCode = (mdn, caseNumber) => async (dispatch, getStore) => {
  // Before API Call
  // API Call
  const response = await dispatch(
    generateQRCodeApi({
      cacheId: "",
      mdn,
      caseNumber: caseNumber,
    })
  ).catch((err) => dispatch(serviceUnavailable()));

  return response
};

export const checkPaymentStatus = (mdn, caseNumber) => async (dispatch, getStore) => {
  // Before API Call
  // API Call
  const response = await dispatch(
    checkPaymentStatusApi({
      cacheId: "",
      mdn,
      caseNumber: caseNumber,
    })
  ).catch((err) => dispatch(serviceUnavailable()));

  return response
};

