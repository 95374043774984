import sessionReducer from "./sessionReducer";
import validationReducer from "../modules/Validation/state/reducers";
import cowrapperReducer from "./cowrapperReducer";
import homeReducer from "./homeReducer";

export default {
  session: sessionReducer,
  validation: validationReducer,
  cowrapper: cowrapperReducer,
  app: homeReducer,
};
