const Config = {
  AppSync: {
    EndPoint: process.env.REACT_APP_GRAPHQL_ENDPOINT,
    Region: process.env.REACT_APP_GRAPHQL_REGION,
    AuthenticationType: process.env.REACT_APP_GRAPHQL_AUTHENTICATION_TYPE,
  },
  Cognito: {
    IdentityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOLID,
    Region: process.env.REACT_APP_COGNITO_REGION,
    UserPoolId: process.env.REACT_APP_COGNITO_USERPOOLID,
    WebClientId: process.env.REACT_APP_COGNITO_USERPOOLWEBCLIENTID,
  },
  Mixpanel: {
    mixpanelId: process.env.REACT_APP_TOKEN_ID
  },
  GTM: {
    gtmId: process.env.REACT_APP_GTM_ID
  }
};

export default Config;