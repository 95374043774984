import {
  callGenerateQrCodeApi,
  setUserDetails,
  setShowServiceUnavailable,
  setPaymentStatus,
  callCheckPaymentStatusApi
} from "./reducers";

export const generateQRCodeApi = ({
  cacheId,
  mdn,
  caseNumber
}) => ({
  type: callGenerateQrCodeApi.toString(),
  meta: {
    async: true,
    blocking: true,
    path: `/payments/v1/sg/generate`,
    method: "POST",
    body: {
      GenerateQRRequest: {
        MobileDeviceNumber: mdn,
        // SessionId: cacheId,
        CustomerCaseNo: caseNumber
      },
    },
  },
});

export const checkPaymentStatusApi = ({
  cacheId,
  mdn,
  caseNumber
}) => ({
  type: callCheckPaymentStatusApi.toString(),
  meta: {
    async: true,
    blocking: true,
    path: `/payments/v1/sg/verify`,
    method: "POST",
    body: {
      VerifyPaymentStatusRequest: {
        MobileDeviceNumber: mdn,
        // SessionId: cacheId,
        CustomerCaseNo: caseNumber
      },
    },
  },
});

export const setUserDetailsData = (mdn, caseNumber) => ({
  type: setUserDetails.toString(),
  payload: { mdn, caseNumber },
});

export const setPaymentStatusUpdate = (paymentStatus) => ({
  type: setPaymentStatus.toString(),
  payload: paymentStatus,
});

export const serviceUnavailable = () => (dispatch) => {
  dispatch({
    type: setShowServiceUnavailable.toString(),
    payload: true,
  });
};
